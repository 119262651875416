<template>
  <div class="container d-flex justify-center">
    <v-row v-if="student">
      <v-col cols="12" md="3" class="d-flex align-center justify-center avatar">
        <div class="">
          <v-avatar size="10rem" v-if="student.avatar">
            <v-img
              
              :src="`${student.avatar}`"
              class="rounded admissionIdPicture"
              height="100%"
              width="100%"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
          <v-icon color="black" size="20rem" v-else class="avatar-svg"
            >mdi-account-tie</v-icon
          >
        </div>
      </v-col>
      <v-col cols="12" md="3">
        <p class="mb-2 text-justify">
          <span class="font-weight-bold">Nom :</span>
          <span class="ml-1">{{ student.lastName }}</span>
        </p>
        <p class="mb-2 text-justify">
          <span class="font-weight-bold">Prénoms :</span>
          <span class="ml-1">{{ student.firstName }}</span>
        </p>
        <p class="mb-2 text-justify">
          <span class="font-weight-bold">Date d'anniversaire:</span>
          <span class="ml-1">{{ student.birthday }}</span>
        </p>
        <p class="mb-2 text-justify">
          <span class="font-weight-bold">Lieu de naissance :</span>
          <span class="ml-1">{{ student.birthPlace }}</span>
        </p>
        <p class="mb-2 text-justify">
          <span class="font-weight-bold">Nationalité :</span>
          <span class="ml-1">{{ student.nationality }}</span>
        </p>
        <p class="mb-2 text-justify">
          <span class="font-weight-bold">Pays :</span>
          <span class="ml-1">{{ student.country }}</span>
        </p>
        <p class="mb-2 text-justify">
          <span class="font-weight-bold">Ville :</span>
          <span class="ml-1">{{ student.city }}</span>
        </p>

        <p class="mb-2 text-justify">
          <span class="font-weight-bold">Téléphone :</span>
          <span class="ml-1">{{ student.phone }}</span>
        </p>
        <p class="mb-2 text-justify">
          <span class="font-weight-bold">Adress:</span>
          <span class="ml-1">{{ student.address }}</span>
        </p>
      </v-col>
      <v-col cols="12" md="6">
        <div class="mt-4">
          <p class="mb-1">
            <span class="font-weight-bold">Option :</span>
            <span>{{ student.department.name }}</span>
          </p>
          <p class="mb-1">
            <span class="font-weight-bold">Niveau :</span>
            <span>{{ student.currentLevel }}</span>
          </p>
        </div>
        <div class="mt-4">
          <p v-if="student.godfather" class="mb-1">
            <span class="font-weight-bold">Parain :</span>
            <span>{{ student.godfather.fullname }}</span>
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>